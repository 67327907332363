import { createGlobalStyle, withTheme } from "styled-components";
import fontFiles from "./Fonts";
import { media } from "utils/Media"
import btnClose from "images/btn-close-large.png"


const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: "DIN Next Regular";
        src: url("${fontFiles.DINNextRegularEOT}");
        src:
            url("${fontFiles.DINNextRegularEOT}?#iefix") format("embedded-opentype"), 
            url("${fontFiles.DINNextRegularWOFF2}") format("woff2"), 
            url("${fontFiles.DINNextRegularWOFF}") format("woff"),
            url("${fontFiles.DINNextRegularTTF}") format("truetype");
        font-display: swap;
        font-style: normal;
    }

    @font-face{
        font-family: "DIN Next Condensed Regular";
        src: url("${fontFiles.DINNextCondensedEOT}");
        src:
            url("${fontFiles.DINNextCondensedEOT}?#iefix") format("eot"),
            url("${fontFiles.DINNextCondensedWOFF2}") format("woff2"),
            url("${fontFiles.DINNextCondensedWOFF}") format("woff"),
            url("${fontFiles.DINNextCondensedTTF}") format("truetype");
        font-display: swap;
        font-style: normal;
    }

    @font-face{
        font-family: "DIN Next Condensed Medium";
        src: url("${fontFiles.DINNextCondensedMediumEOT}");
        src:
            url("${fontFiles.DINNextCondensedMediumEOT}?#iefix") format("eot"),
            url("${fontFiles.DINNextCondensedMediumWOFF2}") format("woff2"),
            url("${fontFiles.DINNextCondensedMediumWOFF}") format("woff"),
            url("${fontFiles.DINNextCondensedMediumTTF}") format("truetype");
        font-display: swap;
        font-style: normal;
    }

    @font-face{
        font-family: "DIN Next Condensed Bold";
        src: url("${fontFiles.DINNextCondensedBoldEOT}");
        src:
            url("${fontFiles.DINNextCondensedBoldEOT}?#iefix") format("eot"),
            url("${fontFiles.DINNextCondensedBoldWOFF2}") format("woff2"),
            url("${fontFiles.DINNextCondensedBoldWOFF}") format("woff"),
            url("${fontFiles.DINNextCondensedBoldTTF}") format("truetype");
        font-display: swap;
        font-style: normal;
    }

    body {
        font-family: ${props => props.theme.font.family.body};
        font-size: ${props => props.theme.font.size.base};
        font-weight: normal;
        line-height: ${props => props.theme.font.lineHeight.base};
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.blackOff};
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
        transition: ${props => props.theme.transitionBase};

        &.nav-active {
            overflow: hidden;
        }
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${props => props.theme.font.family.condensedBold};
        font-weight: normal;
        line-height: ${props => props.theme.font.lineHeight.headings};
        margin-bottom: 1rem;
    }

    h1 {
        font-size: ${props => props.theme.font.h2.size};
        @media ${media.md} {
            font-size: ${props => props.theme.font.h1.size};
        }
    }

    h2 {
        font-size: ${props => props.theme.font.h3.size};
        @media ${media.md} {
            font-size: ${props => props.theme.font.h2.size};
        }
    }

    h3 {
        font-size: ${props => props.theme.font.h4.size};
        @media ${media.md} {
            font-size: ${props => props.theme.font.h3.size};
        }
    }

    h4 {
        font-size: ${props => props.theme.font.h4.size};
    }

    h5 {
        font-size: ${props => props.theme.font.h5.size};
    }

    h6 {
        font-size: ${props => props.theme.font.h6.size};
    }

    p {
        &:last-child {
            margin: 0;
        }
    }

    a {
        color: ${props => props.theme.colors.grey};
        text-decoration: underline;
        &:hover {
            color: ${props => props.theme.colors.white}; 
        }
    }

    strong {
        font-family: ${props => props.theme.font.family.bold};
        letter-spacing: .5px;
    }

    ul {
        padding-left: 20px;
    }

    img {
        max-width: 100%;
    }

    .ril-close {
        background-image: url("${btnClose}") !important;
        width: 60px !important;
        height: 80px !important;
    }
`;

export default withTheme(GlobalStyles);