import React, { Component } from "react"
import styled from "styled-components"
import { Row, Col } from "reactstrap"
import { Link } from "gatsby"
import { media } from "utils/Media"
import Social from "components/shared/Social"
import Nav from "./Nav"
import Button from "components/shared/Button"
import btnMenu from "images/Btn-Menu.png"
import btnMenuMobile from "images/btn-menu-mobile.png"

const HeaderStyled = styled.header`
    position: fixed;
    display: flex;
    align-items: center;
    height: 75px;
    z-index: 2;
    padding: 0 1rem;
    width: 100%;
    background: rgba(82,17,54, .8);
`

const SocialWrap = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    display: none;

    @media ${media.md} {
        display: block;
    }

    ul {
        margin: 0;
    }
`

const NavToggle = styled.button`
    position: relative;
    background-image: url("${btnMenuMobile}");
    background-repeat: no-repeat;
    width: 146px;
    height: 78px;
    background-color: transparent;
    border: 0;

    @media ${media.md} {
        height: 68px;
        background-image: url("${btnMenu}");
    }

    &:hover {
        opacity: .9;
    }
`

const ColStyled = styled(Col)`
    @media ${media.md} {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
`

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            navActive: false
        }
        this.toggleNav = this.toggleNav.bind(this)
    }

    toggleNav() {
        this.setState({
            navActive: !this.state.navActive
        }) 
    }

    render() {
        return(
            <>
                <HeaderStyled scrolled={this.state.scrolled} className="m-nav">
                    <Row className="justify-content-between justify-content-md-start align-items-center w-100">
                        <Col xs={6} className="d-flex align-items-center">
                            <NavToggle onClick={this.toggleNav} />
                        </Col>
                        <ColStyled xs={6} md="auto">
                            {/*<Button color="gradient" to="/tour-dates/" as={Link}>*/}
                            {/*    BOOK NOW*/}
                            {/*</Button>*/}
                        </ColStyled>
                    </Row>
                    <SocialWrap>
                        <Social />
                    </SocialWrap>
                </HeaderStyled>
                <Nav active={this.state.navActive} toggleNav={this.toggleNav} />
            </>
        )
    }
}

export default Header