import DINNextRegularEOT from "./fonts/936930ba-a8da-4765-9e89-0f35cdff223f.eot"
import DINNextRegularWOFF2 from "./fonts/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2"
import DINNextRegularWOFF from "./fonts/ccf25ada-6d2a-4133-95dc-deb039e22999.woff"
import DINNextRegularTTF from "./fonts/126195b4-2fa0-4b95-b5cf-ad9bf10193f0.ttf"

import DINNextCondensedEOT from "./fonts/6fa09720-5b0e-48a5-8895-5af8af928c01.eot"
import DINNextCondensedWOFF2 from "./fonts/5dd9f4c4-cfde-415c-bb51-433569311de5.woff2"
import DINNextCondensedWOFF from "./fonts/3b15daf5-d7fb-4099-93b9-db20fd26c2fe.woff"
import DINNextCondensedTTF from "./fonts/16159505-c85d-44ba-ba2b-64f94e68ddb5.ttf"

import DINNextCondensedMediumEOT from "./fonts/0b46a18d-fb34-4ef7-b5da-5902c510577a.eot"
import DINNextCondensedMediumWOFF2 from "./fonts/76b8cda6-0ded-4197-acab-e314297eb90f.woff2"
import DINNextCondensedMediumWOFF from "./fonts/2613a064-2962-44af-ae6f-3640e06e8dc4.woff"
import DINNextCondensedMediumTTF from "./fonts/2889b152-497e-4ea3-a90d-02854d136a8d.ttf"

import DINNextCondensedBoldEOT from "./fonts/71888b4d-cd66-4c8b-9a5c-b37014e8e4fa.eot"
import DINNextCondensedBoldWOFF2 from "./fonts/258804c2-9ca6-4856-bad7-67cde4f087b4.woff2"
import DINNextCondensedBoldWOFF from "./fonts/0c0418fe-d324-4ab2-978a-e8201c646ca1.woff"
import DINNextCondensedBoldTTF from "./fonts/2c4f6a99-1274-42cd-8d14-fecf6ae449a4.ttf"

export default {
    DINNextRegularEOT,
    DINNextRegularWOFF2,
    DINNextRegularWOFF,
    DINNextRegularTTF,

    DINNextCondensedEOT,
    DINNextCondensedWOFF2,
    DINNextCondensedWOFF,
    DINNextCondensedTTF,

    DINNextCondensedMediumEOT,
    DINNextCondensedMediumWOFF2,
    DINNextCondensedMediumWOFF,
    DINNextCondensedMediumTTF,

    DINNextCondensedBoldEOT,
    DINNextCondensedBoldWOFF2,
    DINNextCondensedBoldWOFF,
    DINNextCondensedBoldTTF
};
