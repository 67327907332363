import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons"

const SocialWrap = styled.ul`
    padding: 0;
    width: 100%;
    margin-bottom: 2rem;

    li {
        display: inline-block;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 1.5rem 0 0;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background: linear-gradient(180deg, #FDBA92 0%, #FCFAFB 100%);
            color: black !important;
        }
    }
`

const Social = () => {
    return(
        <SocialWrap>
            <li>
                <a href="https://www.facebook.com/DreamgirlsMusicalUK/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebookF} />
                    <span className="sr-only">Facebook icon</span>
                </a>
            </li>
            <li>
                <a href="https://twitter.com/Dreamgirls_UK" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} />
                    <span className="sr-only">Twitter icon</span>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/dreamgirls_uk/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                    <span className="sr-only">Instagram icon</span>
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCPin4sjN1QKx558hpRRqjog" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} />
                    <span className="sr-only">YouTube icon</span>
                </a>
            </li>
        </SocialWrap>
    )
}

export default Social