import React from "react"
import styled, { css } from "styled-components"
import btnDefault from "images/btn-default.png"
import btnPing from "images/btn-ping.png"

// Remove color from props to stop invalid html
const Button = styled(({ color, disabled, center, sm, ...props }) => (
    <a {...props}>
        <span>
            {props.children}
        </span>
    </a>
))`
    border: 0;
    display: inline-block;
    vertical-align: middle;
    padding: .85em 1em;
    border-radius: 0;
    transition: background-color .25s ease-out,color .25s ease-out;
    font-family: inherit;
    font-size: .9rem;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    color: #fefefe;
    font-family: ${props => props.theme.font.family.condensedBold};

    position: relative;
    width: 174px;
    height: 54px;
    /* background: 0 0; */
    font-size: 20px;
    max-width: 100%;
    background-color: transparent;

    span {
        background: url("${btnDefault}");
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        line-height: 56px;
        transition: color .5s;
        font-size: ${props => props.theme.font.size.md};
        color: #fff;
        // text-transform: uppercase;
    }

    ${props => props.color === "gradient" && css`
        background: linear-gradient(180deg, #FDBA92 0%, #FCFAFB 100%);
        border-radius: 25px;
        height: 50px;
        text-decoration: none;
        color: black;

        span {
            background: none;
            color: black;
        }

        &:hover {
            text-decoration: none;
            color: black !important;
        }
    `}

    ${props => props.sm && css`
        span {
            font-size: ${props => props.theme.font.size.base};
        }
    `}

    ${props => props.disabled && css`
        cursor: default;
        pointer-events: none;
    `}

    &:after,
    &:before {
        content: "";
        background-image: url("${btnPing}");
        background-size: cover;
        display: inline-block;
        width: 40px;
        height: 40px;
        z-index: 9999;
        position: absolute;
        left: 0;
        top: -15px;
        transform: scale(0);
    }

    &:after {
        width: 30px;
        height: 30px;
        right: 0;
        left: auto;
        top: auto;
        bottom: -10px;
    }

    &:hover {
        background-color: ${props => props.theme.colors.greyDark};
        color: ${props => props.theme.colors.white};
        cursor: pointer;
        border-color: ${props => props.theme.colors.white};

        &:after,
        &:before {
            animation: sheen 2s forwards;
        }

        @keyframes sheen {
            0 {
                transform: scale(0);
            }
            50% {
                transform: scale(1.5);
            }
            100% {
                transform: scale(0);
            }
        }
    }
`;

export default Button