import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import { media } from "utils/Media"
import Social from "components/shared/Social"

const FooterStyled = styled.footer`
    padding: 2rem 1rem;
    background-color: ${props => props.theme.colors.blackOff};
    position: relative;
    z-index: 1;
    font-family: ${props => props.theme.font.family.condensedBold};

    @media ${media.md} {
        padding: 4rem 0;
    }

    ul {
        list-style: none;
        padding: 0;
        li {
            a {
                color: ${props => props.theme.colors.grey};
                text-decoration: none;

                &:hover {
                    color: ${props => props.theme.colors.white};
                    text-decoration: none;
                }
            }
        }
    }
`

const FooterTitle = styled.h3`
    color: ${props => props.theme.colors.whiteOff};
    padding: 0;
    margin: 0;
`

const BottomText = styled.p`
    font-size: ${props => props.theme.font.size.sm};
    font-family: ${props => props.theme.font.family.condensed};
`

const Footer = () => (
    <FooterStyled>
        <Container>

            <Social />

            <Row>
                <Col xs={6} sm="auto">
                    <FooterTitle>Useful links</FooterTitle>
                    <ul>
                        <li>
                            <Link to="/cookie-policy/">Cookie policy</Link>
                        </li>
                        <li>
                            <Link to="/terms-and-conditions/">Terms and conditions</Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy/">Privacy policy</Link>
                        </li>
                    </ul>
                </Col>
                <Col xs={6} sm="auto">
                    ©Copyright 2019 Dreamgirls<br/>
                    All rights reserved<br />
                    Website by Dewynters
                </Col>
                <Col sm="auto">
                    <BottomText>Production photos are of the West End company</BottomText>
                </Col>
            </Row>
        </Container>
    </FooterStyled>
)

export default Footer