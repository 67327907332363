import React, {Component} from "react"
import PropTypes from "prop-types"
import styled, {css} from "styled-components"
import {media} from "utils/Media"
import {useStaticQuery, graphql, Link} from "gatsby"
import {ThemeProvider} from "styled-components"
import 'scss/main.scss'
import SEO from "utils/Seo"
import GlobalStyles from "utils/GlobalStyles"
import theme from "utils/Theme"
import Header from "components/Header/Header"
import Footer from "components/Footer/Footer"
import logoImg from "images/dg-logo-shadow.png"
import bgCastLg from "images/bg-cast-header-1920.jpg"
import bgCastMd from "images/bg-cast-header-991.jpg"
import bgCastSm from "images/bg-cast-header-576.jpg"


const Main = styled.main`
    overflow-x: hidden;
    ${props => props.bg && css`
        background-image: url("${bgCastSm}");
        background-size: 100%;
        background-repeat: no-repeat;
        background-attachment: fixed;
        padding-top: 8rem;
        /* padding-bottom: 4rem; */

        @media ${media.md} {
            background-image: url("${bgCastMd}");
            padding-top: 10rem;
        }

        @media ${media.lg} {
            background-image: url("${bgCastLg}");
        }
    `}

    ${props => props.bgShort && css`
        @media ${media.md} {
            background-attachment: scroll;
        }
    `}
`

const Logo = styled.img`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80%;
    padding-bottom: 4rem;
`

class Layout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: true
        };
    }

    render() {
        const {children, title, description, logo, bgShort, slug} = this.props;
        return (
            <ThemeProvider theme={theme}>
                <>
                    <SEO title={title} description={description} slug={slug} />
                    <GlobalStyles/>
                    <Header siteTitle={title} logo={logo}/>
                    <Main bg={logo} bgShort={bgShort}>
                        {logo &&
                        <Link to="/">
                            <Logo src={logoImg} alt={title} width="400"/>
                        </Link>
                        }
                        {children}
                    </Main>
                    <Footer/>
                </>
            </ThemeProvider>
        )
    }
}


Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
Layout.defaultProps = {
    logo: false,
    bgShort: false,
    title: 'Dreamgirls the Musical on Tour',
    description: 'Let your soul sing with the dazzling multi-award winning ',
}
export default Layout