import React, { Component } from "react";
import styled, { css } from "styled-components"
import { media } from "utils/Media"
import { Link, StaticQuery, graphql } from "gatsby"
import btnClose from "images/btn-close-large.png"
import underline from "images/underline.png"
import ping from "images/btn-ping.png"

const NavWrap = styled.nav`
    background-color: rgba(29,14,10, .9);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    transform: translateX(-100%);
    transition: ${props => props.theme.transitionBase};
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media ${media.sm} {
        width: 75%;
    }
    
    @media ${media.md} {
        width: 50%;
    }

    ${props => props.active && css`
        transform: translateX(0);
    `}

    ul {
        list-style: none;
        padding: 0;
        text-align: center;

        li {
            a {
                text-decoration: none;
                font-size: 3rem;
                font-family: ${props => props.theme.font.family.condensedBold};
                padding: .7rem 1rem;
                /* margin-bottom: .5rem; */
                position: relative;
                outline: 0!important;
                box-shadow: none!important;
                border: none!important;
                color: #fff;
                display: inline-block;
                line-height: 1.1;

                &:before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    width: 0;
                    height: 10px;
                    background-image: url("${underline}");
                    background-repeat: no-repeat;
                    transform: translateX(-50%);
                    background-size: 100% 100%;
                }

                &:after {
                    content: "";
                    background-image: url("${ping}");
                    background-size: 100%;
                    height: 40px;
                    width: 40px;
                    bottom: -15px;
                    right: 50%;
                    position: absolute;
                    transform: scale(0);
                    opacity: 0;
                }

                &:hover {
                    &:before {
                        animation: width100pc .7s cubic-bezier(.43,.04,1,.76) forwards;
                    }
                    &:after {
                        animation: scaleIn .7s cubic-bezier(.43,.04,1,.76) forwards, sheenIn 3s linear .1s infinite;
                    }
                }

                @keyframes scaleIn {
                    0% {
                        opacity: 0;
                        right: 50%; 
                    }
                    100% {
                        opacity: 1;
                        right: -12px; 
                    } 
                }

                @keyframes sheenIn {
                    0% {
                        transform: scale(1); 
                    }
                    50% {
                        transform: scale(2); 
                    }
                    100% {
                        transform: scale(1); 
                    } 
                }
                

                @keyframes width100pc {
                    0% {
                        width: 0; 
                    }
                    100% {
                        width: 100%; 
                    } 
                }
            }
        }
    }
`

const Close = styled.button`
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-image: url("${btnClose}");
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    padding: 0;
    height: 70px;
    width: 70px;
    border: 0;

    &:hover {
        opacity: .9;
    }
`

class Nav extends Component {
    render() {
        return(
            <NavWrap active={this.props.active}>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    {/* <li> */}
                        {/* <Link to="/tour-dates/">Tour Dates</Link> */}
                    {/* </li> */}
                    <li>
                        <Link to="/photos/">Gallery</Link>
                    </li>
                    {/* <li>
                        <Link to="/cast-and-creative/">Cast &amp; Creative</Link>
                    </li> */}
                    {/* <li>
                        <a href="https://dreamgirlswestendstore.com/index.php?page=products&section=Apparel" target="_blank" rel="noopener noreferrer">Shop</a>
                    </li> */}
                </ul>
                <Close onClick={this.props.toggleNav} />
            </NavWrap>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allPhotoPagesJson {
                    edges {
                        node {
                            id
                            title
                            path
                        }
                    }
                }
            }
		`}
        render={data => (
            <Nav data={data} active={props.active} toggleNav={props.toggleNav} />
        )}
    />
)