// match bootstrap sizes, also add xxl
const sizes = {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1600px",
    xxxl: "1920px",
};

const bootstrapGutterWidth = "15px";

const fontSizeBase = 1;

const font = {
    family: {
        condensed: "DIN Next Condensed Regular",
        condensedMedium: "DIN Next Condensed Medium",
        condensedBold: "DIN Next Condensed Bold",
        base: "DIN Next Regular",
        bold: "DIN Next Regular",
        body: `"DIN Next Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
    },
    size: {
        xs: `${fontSizeBase * .75}rem`, // 12px
        sm: `${fontSizeBase * .875}rem`, // 14px
        base: `${fontSizeBase}rem`, // 16px
        md: `${fontSizeBase * 1.125}rem`, // 18px
        lg: `${fontSizeBase * 1.25}rem`, // 20px
        xl: `${fontSizeBase * 1.5}rem` // 24px
    },
    lineHeight: {
        sm: 1.1,
        base: 1.4,
        headings: 1.2
    },
    h1: {
        size: `${fontSizeBase * 2.25}rem`
    },
    h2: {
        size: `${fontSizeBase * 2}rem` 
    },
    h3: {
        size: `${fontSizeBase * 1.85}rem`
    },
    h4: {
        size: `${fontSizeBase * 1.75}rem`, 
    },
    h5: {
        size: `${fontSizeBase * 1.55}rem`, 
    },
    h6: {
        size: `${fontSizeBase * 1.25}rem`
    }
}

export default {
    sizes,
    bootstrapGutterWidth,
    font,
    transitionBase: "all .4s ease-in-out",
    colors: {
        white: "#ffffff",
        whiteOff: "##ffffdc",
        cream: "#FFEFDB",
        black: "#020202",
        blackOff: "#140603",
        grey: "#cccccc",
        blue: "#626C80",
        red: "#FF525C",
        redLight: "#ff1430",
        navy: "#1C2027",
        pink: "#E84261",
        purple: "#521136",
        purpleDark: "#350441",
        yellow: "#FFCC00",
        turquoise: "#A8F1F8",
        green: "#A0E963"
    }
};